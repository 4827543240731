import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from './store/auth.slice'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/public/login/Login'))
const SignUp = React.lazy(() => import('./views/public/signup/SignUp'))
const Logout = React.lazy(() => import('./views/public/logout/Logout'))
const ForgotPassword = React.lazy(() => import('./views/public/forgot/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/public/reset/ResetPassword'))
const VerifyEmail = React.lazy(() => import('./views/public/verify-email/VerifyEmail'))

const App = () => {
  const [loading, setLoading] = useState(true)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const authenticate = (_auth) => {
    //if (!_auth) return null
    setLoading(true)
    dispatch(authActions.set(_auth))
    setLoading(false)
    if (_auth) return true
    return false
  }

  const updateAuth = (_auth, redirect) => {
    //if (!_auth) return null
    _auth.tenants = _auth.tenants ?? []
    if (!_auth.tenants.length && _auth?.user?.type && _auth?.user?.company?.tenants?.length) {
      // preselect all tenants
      _auth.tenants = _auth.user.company.tenants.map(({ id, name }) => ({
        id,
        name,
      }))
    }
    _auth.tenant = _auth.tenant ?? _auth.tenants[0]?.id
    _auth.categories = _auth.categories?.results ? _auth.categories?.results : _auth.categories
    authenticate(_auth)
    if (redirect) document.location = '/'
  }

  useEffect(() => {
    // get stored login
    let _auth = null
    try {
      _auth = localStorage.getItem('auth')
      if (_auth) _auth = JSON.parse(_auth)
    } catch (exception) {}
    console.log('saved auth', _auth)

    // check if logged in
    if (!_auth) {
      // no auth
      console.log('no auth')
      //authenticate(null)
      setLoading(false)
    } else {
      // success
      updateAuth(_auth)
    }
  }, [])

  if (loading)
    return (
      <CContainer className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <CSpinner variant="grow" color="primary" />
      </CContainer>
    )

  if (!auth)
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <CContainer className="vh-100 d-flex flex-column justify-content-center align-items-center">
              <CSpinner variant="grow" color="primary" />
            </CContainer>
          }
        >
          <Routes>
            <Route exact path="/" name="Login" element={<Login updateAuth={updateAuth} />} />
            <Route exact path="/login" name="Login" element={<Login updateAuth={updateAuth} />} />
            <Route exact path="/sign-up" name="Sign Up" element={<SignUp />} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route
              path="/reset-password/:token"
              name="Reset Password"
              element={<ResetPassword />}
            />
            <Route
              path="/verify-email/:token"
              name="Verify Email"
              element={<VerifyEmail onLoading={setLoading} />}
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )

  console.log('isAuth')

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <CContainer className="vh-100 d-flex flex-column justify-content-center align-items-center">
            <CSpinner variant="grow" color="primary" />
          </CContainer>
        }
      >
        <Routes>
          <Route exact path="/logout" element={<Logout authenticate={authenticate} />} />
          <Route
            path="*"
            element={
              auth ? (
                <DefaultLayout authenticate={authenticate} auth={auth} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
