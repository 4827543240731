import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebarShow: true,
    sidebarUnfoldable: false,
    asideShow: false,
    theme: 'light',
  },
  reducers: {
    sidebarToggle(state) {
      state.sidebarShow = !state.sidebarShow
    },
    sidebarShow(state, { payload }) {
      state.sidebarShow = payload
    },
    sidebarUnfoldableToggle(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    asideShow(state, { payload }) {
      state.asideShow = payload
    },
  },
})

export const uiActions = uiSlice.actions
export default uiSlice
