import { createSlice } from '@reduxjs/toolkit'

function updateAuthInLocalStorage(newAuth) {
  localStorage.setItem('auth', JSON.stringify(newAuth))
}

const authSlice = createSlice({
  name: 'auth',
  initialState: null,
  reducers: {
    set(state, { payload }) {
      updateAuthInLocalStorage(payload)
      return payload
    },
    selectTenant(state, { payload }) {
      state.tenant = payload
      updateAuthInLocalStorage(state)
    },
    setTenants(state, { payload }) {
      state.tenants = payload.map(({ id, name, status }) => ({ id, name, status }))
      updateAuthInLocalStorage(state)
    },
    addUpdateTenant(state, { payload }) {
      const tenantsClone = [...state.tenants]
      const foundTenant = tenantsClone.find(({ id }) => id === payload.id)

      if (foundTenant) {
        foundTenant.name = payload.name
      } else {
        tenantsClone.push(payload)
      }

      state.tenants = tenantsClone
      updateAuthInLocalStorage(state)
    },
    setUser(state, { payload }) {
      state.user = payload
      updateAuthInLocalStorage(state)
    },
    setUserCompany(state, { payload }) {
      const updatedCompany = {
        ...state.user.company,
        ...payload,
      }
      state.user.company = updatedCompany

      updateAuthInLocalStorage(state)
    },
  },
})

export const authActions = authSlice.actions

export default authSlice
